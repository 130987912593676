import {Component, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {WebsiteService} from '../../service/website/website.service';
import Swal from 'sweetalert2';
import articleList from './articleList.js'

import {
  SwiperConfigInterface,
  SwiperCoverflowEffectInterface,
  SwiperComponent,
  SwiperNavigationInterface
} from 'ngx-swiper-wrapper';


const coverflowEffectConfig: SwiperCoverflowEffectInterface = {
  rotate: 50,
  stretch: 0,
  depth: 100,
  modifier: 1,
  slideShadows: false
};

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.less',
    'css/animate.css',
    'css/normalize.css',
    'css/bootstrap.css',
    //   'css/sweetalert2.min.css',
    'css/swiper.css',
  ]
})
export class WebsiteComponent implements OnInit {
  //   swiper: any;
  applyPage = false;
  isQrCode = false;
  isArticle = false;
  btnShow = true;
  time = 60;
  timer: any;
  name: string;
  phoneNum: string;
  code: number;
  config: any;
  phoneCode = 'https://www.huijin-bank.com/wx/html/download.html?type=1';
  padCode = 'https://www.huijin-bank.com/wx/html/download.html?type=0';
  index = 0;
  changeHeight = 0;
  content = [];
  list = articleList;

  constructor(
    private http: WebsiteService
  ) {
  }

  ngOnInit() {
    this.config = {
      direction: 'vertical',
      slidesPerView: 1,
      paginationClickable: true,
      spaceBetween: 0,
      mousewheelControl: true,
      speed: 400,
      effect: 'coverflow',
      coverflowEffect: coverflowEffectConfig
    };
    this.initAnimationItems();
    this.playAnimation(0);
  }

  change(event) {
    event.preventDefault();
    event.stopPropagation();
    const deltaY = event.deltaY;
    console.log(deltaY);
    if (this.index === 0 && deltaY < 0) {
      this.changeHeight = 0;
    }
    if (this.index === 9 && deltaY > 0) {
      this.changeHeight = 0;
    }
    this.changeHeight += deltaY;
    if (this.changeHeight > 300 && this.index < 9) {
      this.changeHeight = 0;
      this.index++;
    }
    if (this.changeHeight < -300 && this.index > 0) {
      this.changeHeight = 0;
      this.index--;
    }
    // console.log(this.changeHeight);
    console.log(this.index);
    return false;
  }

  animateView(e, val) {
    $(e.target).addClass('active').parent().siblings().find('a').removeClass('active');
    e.preventDefault();
    this.index = val;
  }

  downloadApp(e, val) {
    e.preventDefault();
    this.index = val;
  }

  appAccount() {
    const t = this;
    if (!t.code) {
      Swal.fire('请输入验证码');
      return;
    }
    if (!t.name) {
      Swal.fire('请输入姓名');
      return;
    }
    if (!t.phoneNum) {
      Swal.fire('请输入手机号');
      return;
    }
    const data = {
      name: t.name,
      code: t.code,
      phone: t.phoneNum
    };
    this.http.applyAccount(data).subscribe(res => {
      Swal.fire(
        '成功',
        '申请试用成功，请等待审核',
        'success'
      );
    });
  }

  clearTimer() {
    clearInterval(this.timer);
  }

  applyTryOut() {
    this.applyPage = true;
  }

  closeApplyPage() {
    this.applyPage = false;
    this.btnShow = true;
    this.clearTimer();
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  sendPhoneNum() {
    console.log(this.phoneNum);
    if (this.isTelNum(this.phoneNum) != 1) {
      Swal.fire('手机号有误');
      return;
    }
    this.btnShow = false;
    clearInterval(this.timer);
    this.time = 60;
    this.timer = setInterval(() => {
      if (this.time > 0) {
        this.time--;
      } else {
        this.btnShow = true;
        this.time = 60;
      }
    }, 1000);
    this.http.queryCode(this.phoneNum).subscribe((res) => {
      Swal.fire(
        '成功',
        '短信验证码已发送,请查收',
        'success'
      );
    });
  }

  isTelNum(telNum) {
    console.log(telNum);
    const telExp = /^13[0-9]|14[5,7]|15[0-3,5-9]|17[0,3,5-8]|18[0-9]|166|198|199|147\d{8}$/;
    if (telNum.toString().length != 11) {
      return 0;
    }
    const forbidden = telExp.test(telNum);
    console.log(forbidden);
    return forbidden ? 1 : -1;
  }

  provisionalApply() {
    Swal.fire('温馨提示',
      '请拨打电话:010-87868060联系我们',
      'info');
  }

  indexChange(o) {
    this.playAnimation(o);
  }

  initAnimationItems() {
    $('.animated').each(function () {
      let aniDuration, aniDelay;

      $(this).attr('data-origin-class', $(this).attr('class'));

      aniDuration = $(this).data('ani-duration');
      aniDelay = $(this).data('ani-delay');

      $(this).css({
        'visibility': 'hidden',
        'animation-duration': aniDuration,
        '-webkit-animation-duration': aniDuration,
        'animation-delay': aniDelay,
        '-webkit-animation-delay': aniDelay
      });
    });
  }

  playAnimation(index) {
    const s = index + 1;
    // console.log(this);
    this.clearAnimation();

    const aniItems = $('.page' + s).find('.animated');

    $(aniItems).each(function () {
      var aniName;
      $(this).css({'visibility': 'visible'});
      aniName = $(this).data('ani-name');
      $(this).addClass(aniName);
    });
  }

  clearAnimation() {
    $('.animated').each(function () {
      $(this).css({'visibility': 'hidden'});
      $(this).attr('class', $(this).data('origin-class'));
    });
  }

  showQrCode(data) {
    this.content = data;
    this.isQrCode = true;
  }
  closeQrCode() {
    this.isArticle = true;
    this.isQrCode = false;
  }
  closeArticle() {
    this.isArticle = false;
  }

}
