import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WebsiteComponent} from './website/website/website.component';

const routes: Routes = [
  {
    path: '',
    component: WebsiteComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
