import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import UserInfo from './UserInfo';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  private testServer = 'https://www.huijin-bank.com/';

  constructor(
    private http: HttpClient
  ) {
  }

  queryCode(phoneNum: string): Observable<any> {
    const requestURL = this.testServer + 'api/verify-code-controller/send-verify-code/' + phoneNum;
    return this.http.request('post', requestURL, {
      body: {
        phone: phoneNum,
      },
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
  }

  applyAccount(data: UserInfo) {
    const requestURL = this.testServer + 'api/verify-code-controller/registerPhone/' + data.code;
    return this.http.request('post', requestURL, {
      body: data,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
  }
}
